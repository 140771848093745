import Vue from "vue";
import Router from "vue-router";

import store from "@/store";
import UnauthenticatedLayout from "@/views/layout/UnauthenticatedLayout";
import AuthenticatedLayout from "@/views/layout/AuthenticatedLayout";
import Login from "@/views/framework/Login";

const Dashboard = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/dashboards/Dashboard.vue"
  );
const Recovery = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/framework/Recovery.vue"
  );
const Reset = () =>
  import(/* webpackChunkName: "rare-bundle" */ "@/views/framework/Reset.vue");
const Profile = () =>
  import(/* webpackChunkName: "rare-bundle" */ "@/views/framework/Profile.vue");

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/unauth",
      component: UnauthenticatedLayout,
      children: [
        {
          path: "/login",
          name: "Login",
          component: Login,
        },
        {
          path: "/recovery",
          name: "Recovery",
          component: Recovery,
        },
        {
          path: "/reset",
          name: "Reset",
          component: Reset,
        },
      ],
    },
    {
      path: "/auth",
      component: AuthenticatedLayout,
      children: [
        {
          path: "/reports",
          name: "Dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/profile",
          name: "profile",
          component: Profile,

          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.path == "/") {
    next({ path: "/login", query: { nl: to.query.nl } }); // Forward name login mode if available
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.meta.requiresAdmin && !store.state.isAdmin()) {
      next("/login");
      return;
    }

    if (to.meta.requiresSuperAdmin && !store.state.isSuperAdmin()) {
      next("/login");
      return;
    }

    if (store.state.user.id > 0) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
