import i18n from "@/plugins/i18n";

let staticData = {
  statisticsPeriods: [],
  days: [],
  dataTableFooterOptions: {},
  defaultPerPage: 50,

  update: () => {
    staticData.locales = [
      {
        id: null,
        value: i18n.t("gbl-required-selection"),
        disabled: true,
      },
      {
        id: "it",
        value: i18n.t("language-it"),
      },
      {
        id: "en",
        value: i18n.t("language-en"),
      },
    ];

    staticData.dataTableFooterOptions = {
      showFirstLastPage: true,
      "items-per-page-options": [1, 5, 10, 20, 50, 100],
    };

    staticData.defaultPerPage = 50;
  },
};

staticData.update();

export default staticData;
