var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"justify-center d-flex mt-n8"},[_c('v-col',{staticClass:"mx-auto",attrs:{"lg":"4","md":"7"}},[(false)?_c('v-card',{staticClass:"card-shadow pt-4 border-radius-xl"},[_c('div',{staticClass:"position-relative text-center"},[_c('p',{staticClass:"text-sm font-weight-bold mb-2 text-secondary text-border short-text-border d-inline z-index-2 bg-white px-3"},[_vm._v(" "+_vm._s(_vm.$t("login-subtitle-1"))+" ")])]),_c('v-form',{attrs:{"id":"login-form"},on:{"submit":function($event){$event.preventDefault();return _vm.login(false)}}},[_c('ValidationObserver',{ref:"spid_observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('div',{staticClass:"card-padding"},[_c('div',{staticClass:"text-center"},[(!_vm.spidLogging)?_c('SpidButton',{on:{"click":function($event){return _vm.login(false)}}},[_vm._v(" "+_vm._s(_vm.$t("login-button-spid"))),_c('img',{staticClass:"ml-1",attrs:{"width":"16","height":"16","src":require("@/assets/spid.svg")}})]):_vm._e()],1),(_vm.spidLogging)?_c('p',{staticClass:"text-center text-sm text-body mb-0 mt-2"},[_c('v-progress-circular',{staticClass:"mb-2",attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"#3A416F"}})],1):_vm._e()])]}}],null,false,439318747)})],1)],1):_vm._e(),_c('v-card',{staticClass:"card-shadow pt-4 mt-8 border-radius-xl"},[_c('div',{staticClass:"position-relative text-center"},[_c('p',{staticClass:"text-sm font-weight-bold mb-2 text-secondary text-border short-text-border d-inline z-index-2 bg-white px-3"},[_vm._v(" "+_vm._s(_vm.$t("login-subtitle-3"))+" ")])]),_c('v-form',{attrs:{"id":"login-form-2"},on:{"submit":function($event){$event.preventDefault();return _vm.login(true)}}},[_c('ValidationObserver',{ref:"otp_observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"card-padding"},[(_vm.namedLogin)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('FormTextInput',{attrs:{"error-messages":errors,"success":valid,"placeholder":_vm.$t('login-first-name-hint')},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('FormTextInput',{attrs:{"error-messages":errors,"success":valid,"placeholder":_vm.$t('login-last-name-hint')},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[(!_vm.namedLogin)?_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"rules":"required|required_fc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('FormTextInput',{attrs:{"disabled":_vm.otpLogging,"error-messages":errors,"success":valid,"placeholder":_vm.$t('login-cf-hint')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login(true)},"focus":function($event){return _vm.focusOnOTP()}},model:{value:(_vm.fiscal_code),callback:function ($$v) {_vm.fiscal_code=$$v},expression:"fiscal_code"}})]}}],null,true)})],1):_vm._e(),(_vm.namedLogin)?_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('FormTextDatePicker',{attrs:{"locale":_vm.$store.state.user.locale,"placeholder":_vm.$t('login-birth-date-hint'),"error-messages":errors,"success":valid},model:{value:(_vm.birth_date),callback:function ($$v) {_vm.birth_date=$$v},expression:"birth_date"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"rules":"required|min:6|max:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('FormTextInput',{attrs:{"disabled":_vm.otpLogging,"error-messages":errors,"success":valid,"placeholder":_vm.$t('login-otp-hint')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login(true)},"focus":function($event){return _vm.focusOnOTP()}},model:{value:(_vm.otp_token),callback:function ($$v) {_vm.otp_token=$$v},expression:"otp_token"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"text-center"},[(!_vm.otpLogging)?_c('UniversalButton',{attrs:{"disabled":invalid},on:{"click":function($event){return _vm.login(true)}}},[_vm._v(" "+_vm._s(_vm.$t("login-button-otp"))+" ")]):_vm._e()],1),(_vm.otpLogging)?_c('p',{staticClass:"text-center text-sm text-body mb-0 mt-2"},[_c('v-progress-circular',{staticClass:"mb-2",attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"#3A416F"}})],1):_vm._e(),(_vm.otpLoginError)?_c('div',{staticClass:"mt-2"},[_c('v-alert',{staticClass:"alert alert-danger",attrs:{"close-icon":"fas fa-times font-size-root text-white","type":"error","dense":"","dismissible":""}},[_c('span',{staticClass:"text-uppercase",domProps:{"textContent":_vm._s(_vm.otpLoginError)}})])],1):_vm._e()],1)]}}])})],1)],1),_c('v-card',{staticClass:"card-shadow mt-8 pt-4 border-radius-xl"},[_c('div',{staticClass:"position-relative text-center"},[_c('div',{staticClass:"mb-2"}),_c('p',{staticClass:"text-sm font-weight-bold mb-2 text-secondary text-border short-text-border d-inline z-index-2 bg-white px-3"},[_vm._v(" "+_vm._s(_vm.$t("login-subtitle-2"))+" ")])]),_c('v-form',{attrs:{"id":"login-form"},on:{"submit":function($event){$event.preventDefault();return _vm.login(false)}}},[_c('ValidationObserver',{ref:"password_observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"card-padding"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('FormTextInput',{attrs:{"disabled":_vm.logging,"error-messages":errors,"success":valid,"placeholder":_vm.$t('login-email-hint')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login(false)},"focus":function($event){return _vm.focusOnPassword()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('FormTextInput',{attrs:{"disabled":_vm.logging,"outlined":"","error-messages":errors,"success":valid,"placeholder":_vm.$t('login-password-hint'),"autocomplete":"off","append-icon":_vm.password_view ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.password_view ? 'password' : 'text'},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login(false)},"click:append":function () { return (_vm.password_view = !_vm.password_view); },"focus":function($event){return _vm.focusOnPassword()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"text-center"},[(!_vm.logging)?_c('UniversalButton',{attrs:{"disabled":invalid},on:{"click":function($event){return _vm.login(false)}}},[_vm._v(" "+_vm._s(_vm.$t("login-button-credentials"))+" ")]):_vm._e()],1),(_vm.logging)?_c('p',{staticClass:"text-center text-sm text-body mb-0 mt-2"},[_c('v-progress-circular',{staticClass:"mb-2",attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"#3A416F"}})],1):_vm._e(),(_vm.loginError)?_c('div',{staticClass:"mt-2"},[_c('v-alert',{staticClass:"alert alert-danger",attrs:{"close-icon":"fas fa-times font-size-root text-white","type":"error","dense":"","dismissible":""}},[_c('span',{staticClass:"text-uppercase",domProps:{"textContent":_vm._s(_vm.loginError)}})])],1):_vm._e(),(!_vm.logging)?_c('p',{staticClass:"text-center text-sm text-body mt-3 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("login-lost-password"))),_c('br'),_c('router-link',{staticClass:"text-dark text-decoration-none font-weight-bolder",attrs:{"to":"/recovery"}},[_vm._v(" "+_vm._s(_vm.$t("login-lost-password-link"))+" ")])],1):_vm._e()],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }