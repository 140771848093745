<template>
  <v-btn
    style="font-size: 20px"
    class="font-weight-bolder btn-default py-4 px-8"
    @click="click($event)"
    color="#2F67B2"
    :disabled="disabled"
    small
  >
    <span style="color: white"><slot></slot> </span>
  </v-btn>
</template>

<script>
export default {
  data() {
    return {};
  },

  watch: {},

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {},

  methods: {
    click($event) {
      this.$emit("click", $event);
    },
  },
};
</script>
