<template>
  <v-app-bar
    :color="background"
    height="auto"
    class="elevation-3 mt-5 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
    :class="
      navbarFixed
        ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
        : ''
    "
    flat
  >
    <v-row class="py-1">
      <v-col cols="12" sm="8" class="d-flex align-center">
        <div>
          <span style="display: inline-block" class="ml-2 header-welcome">
            <v-row>
              <v-col>
                {{
                  $t("labstream-welcome-female", {
                    name: $store.state.user.first_name,
                  })
                }}
                <p
                  v-if="$store.state.user.last_login"
                  class="mb-0 font-weight-bold text-body text-sm"
                >
                  {{
                    $t("header-welcome", {
                      last_login:
                        $store.state &&
                        $store.state.user &&
                        $store.state.user.last_login
                          ? $options.filters.UTCToLocalTime(
                              $store.state.user.last_login
                            )
                          : 0,
                      last_login_ip:
                        $store.state &&
                        $store.state.user &&
                        $store.state.user.last_login_ip
                          ? $store.state.user.last_login_ip
                          : "",
                    })
                  }}
                </p>
              </v-col>
            </v-row>
          </span>
        </div>
      </v-col>

      <v-col cols="12" sm="4" class="d-flex align-center justify-end">
        <v-menu
          bottom
          left
          offset-y
          origin="top right"
          transition="scale-transition"
          v-if="$store.state.spooler_online"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
              <v-badge color="orange" overlap bordered>
                <template v-slot:badge>
                  <span>{{ $store.state.spooler_num_of_copies }}</span>
                </template>

                <v-icon size="24">mdi-printer-check</v-icon>
              </v-badge>
            </v-btn>
          </template>

          <v-list :tile="false" nav>
            <div>
              <v-list-item
                v-for="(n, i) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                :key="`item-${i}`"
              >
                <v-list-item-title @click="selectPrinterSpoolCopies(n)">{{
                  n
                }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>

        <v-menu
          bottom
          left
          min-width="200"
          offset-y
          origin="top right"
          transition="scale-transition"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-body ml-2"
              :color="linkColor"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon size="24">mdi-account</v-icon>
            </v-btn>
          </template>

          <v-list :tile="false" flat nav>
            <template v-for="(p, i) in profile">
              <v-divider
                v-if="p.divider"
                :key="`divider-${i}`"
                class="mb-2 mt-2"
              />
              <v-list-item v-else :key="`item-${i}`">
                <v-list-item-title
                  class="pa-4 list-item-hover-active"
                  v-text="p.title"
                  @click="handleClick(p)"
                />
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
const packageInfo = require("../../../package.json");

import AvatarServices from "@/services/avatars";
import { EventBus } from "@/plugins/bus";

export default {
  name: "app-bar",
  props: {
    background: String,
    linkColor: String,
  },
  data() {
    return {
      mini: false,
      packageInfo: packageInfo,
      navbarFixed: true,
      hasBg: true,
      profile: [],
      notification_snackbar: false,
      notification_timeout: 60000,
      notification: null,
      notification_severity: "primary",
      incCounterRecomputer: 0,
    };
  },

  components: {},

  mounted() {
    this.profile = [{ title: this.$t("header-profile"), url: "/profile" }];

    this.profile = [
      ...this.profile,
      { divider: true },
      { title: this.$t("header-logout"), func: this.logout },
    ];
    EventBus.$on("avatar-changed", this.avatarUpdater);
  },

  methods: {
    avatarUpdater() {
      if (this.$refs.avatar) this.$refs.avatar.loadAvatar();
    },

    buildGravatar: function (notification) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        notification.gravatar +
        "'>"
      );
    },

    checkGravatar: function (notification) {
      return new Promise((resolve, reject) => {
        if (!notification.gravatarFetched) {
          notification.gravatarFetched = true;
          AvatarServices.fetchAvatar(notification.avatar_id)
            .then((result) => {
              notification.gravatar = result.avatar;
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          resolve();
        }
      });
    },

    handleClick: function (item) {
      if (item.func) item.func();
      else if (item.url) {
        if (item.url != this.$router.currentRoute.path)
          this.$router.push(item.url);
      }
    },

    logout: function () {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push("/login").catch(() => {});
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("/login").catch(() => {});
        });
    },
  },
};
</script>
