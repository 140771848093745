var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"elevation-3 mt-5 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none",class:_vm.navbarFixed
      ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
      : '',attrs:{"color":_vm.background,"height":"auto","flat":""}},[_c('v-row',{staticClass:"py-1"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"8"}},[_c('div',[_c('span',{staticClass:"ml-2 header-welcome",staticStyle:{"display":"inline-block"}},[_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t("labstream-welcome-female", { name: _vm.$store.state.user.first_name, }))+" "),(_vm.$store.state.user.last_login)?_c('p',{staticClass:"mb-0 font-weight-bold text-body text-sm"},[_vm._v(" "+_vm._s(_vm.$t("header-welcome", { last_login: _vm.$store.state && _vm.$store.state.user && _vm.$store.state.user.last_login ? _vm.$options.filters.UTCToLocalTime( _vm.$store.state.user.last_login ) : 0, last_login_ip: _vm.$store.state && _vm.$store.state.user && _vm.$store.state.user.last_login_ip ? _vm.$store.state.user.last_login_ip : "", }))+" ")]):_vm._e()])],1)],1)])]),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12","sm":"4"}},[(_vm.$store.state.spooler_online)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var attrs = ref.attrs;
      var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"orange","overlap":"","bordered":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$store.state.spooler_num_of_copies))])]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v("mdi-printer-check")])],1)],1)]}}],null,false,589436944)},[_c('v-list',{attrs:{"tile":false,"nav":""}},[_c('div',_vm._l(([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),function(n,i){return _c('v-list-item',{key:("item-" + i)},[_c('v-list-item-title',{on:{"click":function($event){return _vm.selectPrinterSpoolCopies(n)}}},[_vm._v(_vm._s(n))])],1)}),1)])],1):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var attrs = ref.attrs;
      var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-body ml-2",class:{ 'btn-dark-hover': !_vm.hasBg, 'btn-hover': _vm.hasBg },attrs:{"icon":"","color":_vm.linkColor,"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24"}},[_vm._v("mdi-account")])],1)]}}])},[_c('v-list',{attrs:{"tile":false,"flat":"","nav":""}},[_vm._l((_vm.profile),function(p,i){return [(p.divider)?_c('v-divider',{key:("divider-" + i),staticClass:"mb-2 mt-2"}):_c('v-list-item',{key:("item-" + i)},[_c('v-list-item-title',{staticClass:"pa-4 list-item-hover-active",domProps:{"textContent":_vm._s(p.title)},on:{"click":function($event){return _vm.handleClick(p)}}})],1)]})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }