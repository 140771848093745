<template>
  <v-app>
    <v-main>
      <v-overlay :z-index="999999" :value="$store.state.global_overlay">
        <v-row
          ><v-col
            ><v-alert
              style="background-color: #2d3a5e"
              class="alert-notify"
              color="#bf126c"
              border="left"
              elevation="2"
              colored-border
            >
              <span class="text-center text-sm"
                >{{ $store.state.global_overlay_text
                }}<v-progress-circular
                  :indeterminate="true"
                  :rotate="0"
                  :size="24"
                  :width="3"
                  color="#bf126c"
                  class="ml-4"
                ></v-progress-circular>
                <v-progress-linear
                  v-if="$store.state.global_overlay_progress == 0"
                  class="mt-2"
                  indeterminate
                  color="yellow darken-2"
                ></v-progress-linear>
                <v-progress-linear
                  v-if="$store.state.global_overlay_progress > 0"
                  class="mt-2"
                  :value="$store.state.global_overlay_progress * 100"
                  color="yellow darken-2"
                ></v-progress-linear></span></v-alert></v-col
        ></v-row>
      </v-overlay>

      <app-bar
        v-if="$route.name != 'Profile'"
        background="bg-transparent"
        has-bg
      ></app-bar>
      <app-bar
        v-else-if="$route.name == 'Profile'"
        background="bg-default"
        has-bg
      ></app-bar>
      <app-bar v-else background="primary" linkColor="rgba(0,0,0,.6)"></app-bar>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here --><router-view
        ></router-view>
      </fade-transition>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </v-main>
  </v-app>
</template>
<script>
// /* eslint-disable no-new */

import { FadeTransition } from "vue2-transitions";
import AppBar from "@/components/mockup/AppBar.vue";
import ContentFooter from "@/components/mockup/Footer.vue";

export default {
  components: {
    ContentFooter,
    FadeTransition,
    AppBar,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.$vuetify.lang.current = this.$store.state.user.locale;
  },
};
</script>
<style lang="scss">
.v-application--is-ltr {
  .v-main:not(.auth-pages) {
    padding-left: 0 !important;
  }
}
</style>
