<template>
  <v-container>
    <v-row class="justify-center d-flex mt-n8">
      <v-col lg="4" md="7" class="mx-auto">
        <v-card v-if="false" class="card-shadow pt-4 border-radius-xl">
          <div class="position-relative text-center">
            <p
              class="text-sm font-weight-bold mb-2 text-secondary text-border short-text-border d-inline z-index-2 bg-white px-3"
            >
              {{ $t("login-subtitle-1") }}
            </p>
          </div>
          <v-form @submit.prevent="login(false)" id="login-form">
            <ValidationObserver ref="spid_observer" v-slot="{}">
              <div class="card-padding">
                <div class="text-center">
                  <SpidButton v-if="!spidLogging" @click="login(false)">
                    {{ $t("login-button-spid")
                    }}<img
                      class="ml-1"
                      width="16"
                      height="16"
                      src="@/assets/spid.svg"
                    />
                  </SpidButton>
                </div>
                <p
                  v-if="spidLogging"
                  class="text-center text-sm text-body mb-0 mt-2"
                >
                  <v-progress-circular
                    class="mb-2"
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="#3A416F"
                  ></v-progress-circular>
                </p>
              </div>
            </ValidationObserver>
          </v-form>
        </v-card>
        <v-card class="card-shadow pt-4 mt-8 border-radius-xl">
          <div class="position-relative text-center">
            <p
              class="text-sm font-weight-bold mb-2 text-secondary text-border short-text-border d-inline z-index-2 bg-white px-3"
            >
              {{ $t("login-subtitle-3") }}
            </p>
          </div>
          <v-form @submit.prevent="login(true)" id="login-form-2">
            <ValidationObserver ref="otp_observer" v-slot="{ invalid }">
              <div class="card-padding">
                <v-row v-if="namedLogin" dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <FormTextInput
                        v-model="first_name"
                        :error-messages="errors"
                        :success="valid"
                        :placeholder="$t('login-first-name-hint')"
                      >
                      </FormTextInput>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <FormTextInput
                        v-model="last_name"
                        :error-messages="errors"
                        :success="valid"
                        :placeholder="$t('login-last-name-hint')"
                      >
                      </FormTextInput>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col v-if="!namedLogin" cols="7">
                    <ValidationProvider
                      rules="required|required_fc"
                      v-slot="{ errors, valid }"
                    >
                      <FormTextInput
                        v-on:keyup.enter="login(true)"
                        :disabled="otpLogging"
                        v-model="fiscal_code"
                        :error-messages="errors"
                        :success="valid"
                        :placeholder="$t('login-cf-hint')"
                        @focus="focusOnOTP()"
                      >
                      </FormTextInput>
                    </ValidationProvider>
                  </v-col>
                  <v-col v-if="namedLogin">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <FormTextDatePicker
                        :locale="$store.state.user.locale"
                        :placeholder="$t('login-birth-date-hint')"
                        v-model="birth_date"
                        :error-messages="errors"
                        :success="valid"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="5">
                    <ValidationProvider
                      rules="required|min:6|max:6"
                      v-slot="{ errors, valid }"
                    >
                      <FormTextInput
                        v-on:keyup.enter="login(true)"
                        :disabled="otpLogging"
                        v-model="otp_token"
                        :error-messages="errors"
                        :success="valid"
                        :placeholder="$t('login-otp-hint')"
                        @focus="focusOnOTP()"
                      >
                      </FormTextInput>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <div class="text-center">
                  <UniversalButton
                    :disabled="invalid"
                    v-if="!otpLogging"
                    @click="login(true)"
                  >
                    {{ $t("login-button-otp") }}
                  </UniversalButton>
                </div>
                <p
                  v-if="otpLogging"
                  class="text-center text-sm text-body mb-0 mt-2"
                >
                  <v-progress-circular
                    class="mb-2"
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="#3A416F"
                  ></v-progress-circular>
                </p>

                <div class="mt-2" v-if="otpLoginError">
                  <v-alert
                    close-icon="fas fa-times font-size-root text-white"
                    class="alert alert-danger"
                    type="error"
                    dense
                    dismissible
                  >
                    <span class="text-uppercase" v-text="otpLoginError" />
                  </v-alert>
                </div>
              </div>
            </ValidationObserver>
          </v-form>
        </v-card>
        <v-card class="card-shadow mt-8 pt-4 border-radius-xl">
          <div class="position-relative text-center">
            <div class="mb-2" />
            <p
              class="text-sm font-weight-bold mb-2 text-secondary text-border short-text-border d-inline z-index-2 bg-white px-3"
            >
              {{ $t("login-subtitle-2") }}
            </p>
          </div>
          <v-form @submit.prevent="login(false)" id="login-form">
            <ValidationObserver ref="password_observer" v-slot="{ invalid }">
              <div class="card-padding">
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors, valid }"
                    >
                      <FormTextInput
                        v-on:keyup.enter="login(false)"
                        :disabled="logging"
                        v-model="email"
                        :error-messages="errors"
                        :success="valid"
                        :placeholder="$t('login-email-hint')"
                        @focus="focusOnPassword()"
                      >
                      </FormTextInput>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <FormTextInput
                        v-on:keyup.enter="login(false)"
                        :disabled="logging"
                        outlined
                        :error-messages="errors"
                        :success="valid"
                        v-model="password"
                        :placeholder="$t('login-password-hint')"
                        autocomplete="off"
                        :append-icon="password_view ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="() => (password_view = !password_view)"
                        :type="password_view ? 'password' : 'text'"
                        @focus="focusOnPassword()"
                      >
                      </FormTextInput>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <div class="text-center">
                  <UniversalButton
                    :disabled="invalid"
                    v-if="!logging"
                    @click="login(false)"
                  >
                    {{ $t("login-button-credentials") }}
                  </UniversalButton>
                </div>
                <p
                  v-if="logging"
                  class="text-center text-sm text-body mb-0 mt-2"
                >
                  <v-progress-circular
                    class="mb-2"
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="#3A416F"
                  ></v-progress-circular>
                </p>

                <div class="mt-2" v-if="loginError">
                  <v-alert
                    close-icon="fas fa-times font-size-root text-white"
                    class="alert alert-danger"
                    type="error"
                    dense
                    dismissible
                  >
                    <span class="text-uppercase" v-text="loginError" />
                  </v-alert>
                </div>

                <p
                  v-if="!logging"
                  class="text-center text-sm text-body mt-3 mb-0"
                >
                  {{ $t("login-lost-password") }}<br />

                  <router-link
                    class="text-dark text-decoration-none font-weight-bolder"
                    to="/recovery"
                  >
                    {{ $t("login-lost-password-link") }}
                  </router-link>
                </p>
              </div>
            </ValidationObserver>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vee from "@/plugins/vee-validate.js";
import staticData from "@/apis/static";
import SpidButton from "@/../../shared/app/components/gui/SpidButton";

export default {
  data() {
    return {
      logging: false,
      otpLogging: false,
      email: "",
      password: "",
      otp_token: "",
      fiscal_code: "",
      last_name: "",
      first_name: "",
      birth_date: null,
      loginError: "",
      otpLoginError: "",
      password_view: true,
      default_landing_page: "/reports",
      namedLogin: false,
    };
  },

  mounted() {
    this.logging = false;
    this.$refs.otp_observer.reset();
    this.$refs.password_observer.reset();
    window.login = this.$options.methods.login.bind(this);
    if (this.$route.query.nl) this.namedLogin = true;
  },

  computed: {},

  components: {
    SpidButton,
  },

  methods: {
    focusOnOTP() {
      this.$refs.password_observer.reset();
      this.email = null;
      this.password = null;
      this.loginError = null;
    },

    focusOnPassword() {
      this.$refs.otp_observer.reset();
      this.fiscal_code = null;
      this.otp_token = null;
      this.otpLoginError = null;
    },

    parseError(err) {
      if (err.substr && err.substr(0, 3) == "tr-") {
        let tr = this.$t(err.substr(3));
        return tr;
      }

      return err;
    },

    login: function (otp) {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let email = this.email;
      let password = this.password;
      let otp_token = this.otp_token;
      let fiscal_code = this.fiscal_code;
      let first_name = this.first_name;
      let last_name = this.last_name;
      let birth_date = this.birth_date;

      this.loginError = null;
      this.otpLoginError = null;
      if (otp) this.otpLogging = true;
      else this.logging = true;
      this.$store
        .dispatch("login", {
          otp,
          fiscal_code,
          otp_token,
          email,
          password,
          timezone,
          first_name,
          last_name,
          birth_date,
        })
        .then(() => {
          this.$store
            .dispatch("fetchStaticData")
            .then(() => {
              this.$i18n.locale = this.$store.state.user.locale;
              vee.setupLocale(); // Required to regenerate the locales in the custom messages
              staticData.update();
              this.$router.push(this.default_landing_page);
            })
            .catch((err) => {
              if (otp) this.otpLoginError = this.parseError(err);
              else this.loginError = this.parseError(err);
              this.logging = false;
              this.otpLogging = false;
            });
        })
        .catch((err) => {
          if (otp) this.otpLoginError = this.parseError(err);
          else this.loginError = this.parseError(err);
          this.logging = false;
          this.otpLogging = false;
        });
    },
  },
};
</script>

<style>
.otp-code-field {
  max-width: 100px;
  margin: auto;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
}
</style>
